import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import Loader from "../../sharedComponent/Loader";
import { useLocation, useParams } from "react-router-dom";

const OrderConfirmationDetails = () => {
  const params = useParams();
  let id = params.id
  const orders = useLocation().state;
  
  const { customerData, mainClass } = useContext(AppContext);
  const [OrderConfirmationDetails, setOrderConfirmationDetails] = useState({
    ...orders,
    load_details: {},
    transporter_details: [{}],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderCOnfirmDetails();
  }, []);
  const getOrderCOnfirmDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.ORDER_CONFIRMATION_DETAILS,
      JSON.stringify({
        ...customerData,
        load_id: id,
      })
    ).then((res) => {
      setLoading(false);
      if (res.json.result) setOrderConfirmationDetails(res.json);
    });
  };

  return (
    <main className={`vehicle-list ${mainClass}`} id="cxMain">
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <section className="common-body details">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Reference Number (Offer Freight)</label>
              <p>{OrderConfirmationDetails.load_details.trip_reference_no}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Operation Number</label>
              <p>{OrderConfirmationDetails.load_details.trip_operation_no}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Company Name (Shipper)</label>
              <p>{OrderConfirmationDetails.load_details.shipper}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Cargo Type</label>
              <p>{OrderConfirmationDetails.load_details.cargo_type}</p>
            </div>

            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Quantity</label>
              <p>{OrderConfirmationDetails.load_details.quantity}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Unit Price</label>
              <p>{OrderConfirmationDetails.load_details.unit_price}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Total Price</label>
              <p>{OrderConfirmationDetails.load_details.total_price}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Loading Place</label>
              <p>{OrderConfirmationDetails.load_details.loading_place}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Expected Loading Date</label>
              <p>{OrderConfirmationDetails.load_details.loading_date}</p>
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="">Exp. Arrival Time At The Destination</label>
              <p>
                {OrderConfirmationDetails.load_details.arrival_date}{" "}
                {OrderConfirmationDetails.load_details.arrival_time}
              </p>
            </div>
          </div>
          <span className="confirm-detail-heading mt-4 mb-4">
            Transporter Details
          </span>
          <div style={{ width: "100%", overflow: "auto" }}>
            <table className=" " style={{ minWidth: "700px" }}>
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">Transporter’s Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Loading Truck Plate Number</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Name</label>
                  </td>
                  <td>
                    <label htmlFor="">Driver Cell Phone</label>
                  </td>
                </tr>
              </thead>
              <tbody>
                {OrderConfirmationDetails.transporter_details.map(
                  (item, index) => {
                    return (
                      <tr className="t-row-confirm mb-3" key={"item" + index}>
                        <td>{item.transporter}</td>
                        <td>
                          <p>{item.plate_no}</p>{" "}
                        </td>
                        <td>
                          <p>{item.driver_name}</p>{" "}
                        </td>
                        <td>
                          <p>{item.driver_phone_no}</p>{" "}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </main>
  );
};

export default OrderConfirmationDetails;
