import React, { useEffect } from "react";
import ic_vehicle from "../assets/images/load_image.svg";
export default function DragAndDropForVehicles({ setFile }) {
  let id = "DropZone";
  useEffect(() => {
    initDragAndDrop();
  }, []);
  const initDragAndDrop = () => {
    var dropZone = document.getElementById(id);
    // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
    dropZone &&
      dropZone.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      });
    dropZone && dropZone.addEventListener("drop", drop);
  };
  function drop(e) {
    e.stopPropagation();
    e.preventDefault();
    let file = "";
    if (e.target && e.target.files) {
      file = e.target.files;
    } else {
      file = e.dataTransfer.files;
    }
    setFile(file);
  }

  return (
    <div className="border-block " id={id}>
      <div className="uploading">
        <img src={ic_vehicle} alt="" />
        <div>
          <label className="m-0 mt-3 drag-drop">
            Drag & Drop files to upload
          </label>
          <br />
          <label className="drag-drop">OR</label>
        </div>
        <a
          href="#"
          style={{ color: "#4D9DE7" }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("uploadVehicleImages").click();
          }}
        >
          Browse Files
        </a>
        <input
          multiple
          onChange={drop}
          accept="image/png, image/gif, image/jpeg"
          id={"uploadVehicleImages"}
          type="file"
          name="vehicle_images"
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
