import React,{useContext} from "react";
import { AppContext } from "../context/user/AppContext";


const FAQ = () => {
  const { mainClass } = useContext(AppContext);

  return (
    <>
   
    <main className={`main-section-faq ${mainClass}`} id="cxMain">
      <section id="faq" className="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="accordion mt-3" id="accordionPanelsStayOpenExample">
                <div className="accordion-item mt-3">
                  <h1 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Qui, illo?{" "}
                    </button>
                  </h1>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Ex distinctio, ipsum voluptatum asperiores id nisi omnis
                      corporis provident eius aspernatur possimus, sint
                      reiciendis officia inventore! Vel quam quidem laboriosam
                      doloribus. Laudantium alias eos aliquam culpa quis?
                      Eveniet perspiciatis aperiam doloribus a reprehenderit
                      optio, culpa unde quibusdam eius ullam necessitatibus
                      aspernatur.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, aperiam.
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias rem deserunt voluptatem suscipit eius iure, totam ab iste maiores corrupti porro optio expedita voluptas explicabo magnam corporis quaerat debitis, reiciendis, ad excepturi quo quia reprehenderit! Sint, soluta ad quae impedit repellendus numquam nobis expedita excepturi tempore possimus? Quas, blanditiis modi.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, aperiam.
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias rem deserunt voluptatem suscipit eius iure, totam ab iste maiores corrupti porro optio expedita voluptas explicabo magnam corporis quaerat debitis, reiciendis, ad excepturi quo quia reprehenderit! Sint, soluta ad quae impedit repellendus numquam nobis expedita excepturi tempore possimus? Quas, blanditiis modi.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFour"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFour"
                    >
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, aperiam.
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingFour"
                  >
                    <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias rem deserunt voluptatem suscipit eius iure, totam ab iste maiores corrupti porro optio expedita voluptas explicabo magnam corporis quaerat debitis, reiciendis, ad excepturi quo quia reprehenderit! Sint, soluta ad quae impedit repellendus numquam nobis expedita excepturi tempore possimus? Quas, blanditiis modi.
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    </>
  );
};

export default FAQ;
