import React,{useContext} from "react";
import AbhayHeader from "../../sharedComponent/AbhayHeader";
import Sidebar from "../../sharedComponent/Sidebar";
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";

const Contracts = () => {
  
  const { mainClassB, profileDetails } = useContext(AppContext);
    $(document).ready(function () {
      $('.dataList').DataTable( 
        
      );
  });
  return (
    <div>
      <main className={`contract-main-section  ${mainClassB}`} id="cxMainB">
        
        {/* Shipper Contract Section */}
        <section className="shipper-contract-section  mb-4">
          <div>
            <p className="contract-paragraph ">
              Shipper’s standard General contract that will be customized based
              on the type of required services whether it is Transport Market
              place or Delivery system.
            </p>
          </div>
          {/* <div className="contract-rates" style={{ width: "100%", overflow: "auto" }}>
            <h4 className="shipper-rate-heading">TRANSPORTATION RATE(S)</h4>
            <table className="vehicle-offer-direct-order-table" id="" style={{ width: "100%", overflow: "auto" , minWidth:"1000px"}}>
              <thead>
                <tr>
                  <td>
                    <label htmlFor="">S.No.</label>
                  </td>
                  <td>
                    <label htmlFor="">Type of Charges</label>
                  </td>
                  <td>
                    <label htmlFor="">Per 20* if container are loaded paired</label>
                  </td>
                  <td>
                    <label htmlFor="">
                    Per 40gp
                    </label>
                  </td>
                  <td>
                    <label htmlFor="">Per Quintal</label>
                  </td>
                  <td>
                    <label htmlFor="">Currency</label>
                  </td>
                  <td>
                    <label htmlFor="">Remarks</label>
                  </td>
                  
                </tr>
              </thead>
              <tbody>
                <tr className="Rowbgcolor">
                  <td>
                  1
                  </td>
                  <td>
                  Port Handling Charges
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  
                </tr>
                <tr className="t-row">
                  <td>
                   2
                  </td>
                  <td>
                  Inland Transportation Charges
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  
                </tr>
                <tr className="t-row">
                  <td>
                   3
                  </td>
                  <td>
                  Service/Clearance Fee
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  <td>
                    <p className="data m-0"></p>
                  </td>
                  
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="button  text-right mt-4" style={{padding:"0 20px"}}>
            <a href={ApiConfig.BASE_URL_FOR_IMAGES + profileDetails?.profile?.contract} target="_blank" className="submit-btn ">
            View Full Contract            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contracts;
