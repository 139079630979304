import React, { useContext, useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ic_options_menu from "../assets/images/ic_options.svg";
import { AppContext } from "../context/user/AppContext";
import Loader from "../sharedComponent/Loader";

const Notifications = () => {
  const { mainClass, customerData } = useContext(AppContext);
  const [notification, setNotification] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNotification();
  }, []);
  const getNotification = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      setLoading(false);
      if (res.json.result) setNotification(res.json.notifications);
    });
  };

  return (
    <>
      <main className={`main-body-Notification ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader />
        ) : (
          <>
            {notification &&
              notification.length &&
              notification.map((notify, index) => {
                return (
                  <section
                    className="common-body notification-block mb-2"
                    key={"notify" + index}
                  >
                    <div className="top d-flex justify-content-between ">
                      <p className="notifications mb-0">
                        {notify.notification_text}
                      </p>
                      <a href="">
                        <img
                          className="option-menu"
                          src={ic_options_menu}
                          alt=""
                        />
                      </a>
                    </div>
                    <label htmlFor="">{notify.notification_time}</label>
                  </section>
                );
              })}
          </>
        )}
      </main>
    </>
  );
};

export default Notifications;
