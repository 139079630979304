import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import view from "../../assets/images/ic_view.svg";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_excel.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_vehicle from "../../assets/images/load_image.svg";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

const OfferGoodsList = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { customerData, mainClass } = useContext(AppContext);
  const [OfferGoodsList, setOfferGoodsList] = useState([]);

  useEffect(() => {
    getOfferGoodsList();

    return () => {
      setOfferGoodsList([]);
    };
  }, []);

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });

  const getOfferGoodsList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.OFFER_LOAD_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false);
        setOfferGoodsList(res.json.load_list);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className={`vehicle cx-active-sidebar ${mainClass}`} id="cxMain">
        <div className="d-flex">
          <div className="input-group justify-content-end ">
            <div className="icon-group mt-2">
              <ul className="d-flex list-unstyled">
                <li className="nav-item icons p-2 me-3 ">
                  <Link to="#">
                    <img
                      src={ic_export}
                      className="add mb-2"
                      alt="send-load-icon"
                    />
                  </Link>
                </li>
                <li className="nav-item icons p-2 me-3">
                  <Link to="#">
                    <img src={ic_import} className="add" alt="send-load-icon" />
                  </Link>
                </li>
                <li className="nav-item icons p-2">
                  <Link to="/AddOfferGoods">
                    <img src={ic_add} className="add" alt="send-load-icon" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ width: "100%", overflow: "auto", padding: "30px 0" }}>
          <div className="" style={{ overflow: "auto", padding: "30px 0" }}>
            {loading ? (
              <Loader />
            ) : (
              <table
                className="dataList"
                id="offerGoodsList"
                style={{ minWidth: "2600px" }}
              >
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    <th>
                      <label>Reference No.</label>
                    </th>
                    <th>
                      <label>Company Name</label>
                    </th>
                    <th>
                      <label>Bill of Lading</label>
                    </th>
                    <th>
                      <label>Packing List</label>
                    </th>

                    <th>
                      <label>Cargo Type</label>
                    </th>
                    <th>
                      <label>Quantity</label>
                    </th>
                    <th>
                      <label>Estimated Arrival Date</label>
                    </th>

                    <th>
                      <label>Insurance</label>
                    </th>
                    <th>
                      <label>Start Address</label>
                    </th>
                    <th>
                      <label>End Address</label>
                    </th>
                    <th>
                      <label>Start Country</label>
                    </th>
                    <th>
                      <label>End Country</label>
                    </th>
                    <th>
                      <label>Unit</label>
                    </th>

                    <th>
                      <label>Status</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {OfferGoodsList ? (
                    OfferGoodsList.length &&
                    OfferGoodsList.map((offerGood, index) => {
                      return (
                        <tr
                          className="Rowbgcolor p-2"
                          key={"offerGood" + index}
                          state={offerGood}
                        >
                          <td>{index + 1}</td>
                          <td
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              color: "#F08A29",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              navigate(
                                "/DetailsOfferGoods/" + offerGood.trip_id
                              )
                            }
                            id={offerGood.trip_id + "_" + index}
                          >
                            {offerGood.trip_reference_no}
                          </td>
                          <td
                            id={offerGood.trip_id + "_" + index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {offerGood.trip_company_name}
                          </td>

                          <td>
                            <a
                              href={
                                ApiConfig.BASE_URL_FOR_IMAGES +
                                offerGood.trip_bill_of_landing
                              }
                              id={offerGood.trip_id + "_" + index}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  offerGood.trip_bill_of_landing === ""
                                    ? ic_vehicle
                                    : ApiConfig.BASE_URL_FOR_IMAGES +
                                      offerGood?.trip_bill_of_landing
                                }
                                alt=""
                              />
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                ApiConfig.BASE_URL_FOR_IMAGES +
                                offerGood.trip_packing_list
                              }
                              id={offerGood.trip_id + "_" + index}
                              style={{
                                textTransform: "capitalize",
                                color: "#F08A29",
                                textDecoration: "underline",
                              }}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  offerGood.trip_packing_list
                                }
                                alt=""
                              />
                            </a>
                          </td>

                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.cargo_type}
                          </td>
                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.quantity}
                          </td>
                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.estimated_arrival_date}
                          </td>

                          <td>
                            <a
                              herf={
                                ApiConfig.BASE_URL_FOR_IMAGES +
                                offerGood.trip_insurance
                              }
                              id={offerGood.trip_id + "_" + index}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  offerGood.trip_insurance
                                }
                                alt=""
                              />
                            </a>
                          </td>
                          <td
                            className="text-truncate"
                            style={{ maxWidth: "150px" }}
                            id={offerGood.trip_id + "_" + index}
                          >
                            {offerGood.trip_start_address}
                          </td>
                          <td
                            className="text-truncate"
                            style={{ maxWidth: "150px" }}
                            id={offerGood.trip_id + "_" + index}
                          >
                            {offerGood.trip_end_address}
                          </td>
                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.trip_start_country}
                          </td>
                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.trip_end_country}
                          </td>
                          <td id={offerGood.trip_id + "_" + index}>
                            {offerGood.unit}
                          </td>

                          <td
                            id={offerGood.trip_id + "_" + index}
                            // className="status-VehicleRequestSent"
                            style={{
                              textTransform: "capitalize",
                              color:
                                offerGood.trip_status === "rejected"
                                  ? "#F74242"
                                  : offerGood.trip_status === "approved"
                                  ? "#42AE21"
                                  : offerGood.trip_status === "requested"
                                  ? "#F29E4E"
                                  : offerGood.trip_status === "confirmed"
                                  ? "#42AE21"
                                  : "",
                            }}
                          >
                            {offerGood.trip_status}
                          </td>
                          {/* <td>
                            <Link
                              to={"/DetailsOfferGoods/" + offerGood.trip_id}
                              state={offerGood}
                            >
                              <img src={view} alt="" />
                            </Link>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <span>no data found</span>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OfferGoodsList;
