import React, { useState } from "react";
import "../../assets/css/OngoingTranStatus.scss";
import user_1 from "../../assets/images/header/ChangeDriver/user_1.svg";
import user_2 from "../../assets/images/header/ChangeDriver/user_2.svg";
import user_3 from "../../assets/images/header/ChangeDriver/user_3.svg";
import user_4 from "../../assets/images/header/ChangeDriver/user_4.svg";
import { Link } from "react-router-dom";


const ChangeDriver = () => {
  return (
    <React.Fragment>
      <section>
        <div class="cx-account-out">
          <div class=" Account-content">
            <div class="Account-header">
              <div
                className="Account offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasDriverAccount"
                aria-labelledby="offcanvasDriverAccount"
              >
                <div class="offcanvas-header justify-content-between">
                  <h5 className="ms-4  card-heading">Change Driver</h5>

                  <button
                    type="button"
                    class="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <ul className="list-container">
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_1} alt="" />
                          <p className=" change-driver-list m-2 ">Thosmas Muller</p>
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />{" "}
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_2} alt="" />
                          <p className=" change-driver-list m-2 " >Harry Kane</p >
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />{" "}
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_3} alt="" />
                          <p className=" change-driver-list m-2 " > David Beckhamo</p >
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />{" "}
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_4} alt="" />
                          <p className=" change-driver-list m-2 " > Lionel Messi.</p >
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_1} alt="" />
                          <p className=" change-driver-list m-2 " > Cristiano Ronaldo</p >
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />{" "}
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_3} alt="" />
                          <p className=" change-driver-list m-2 " > Zlatan Ibrahimović </p >
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />{" "}
                      </div>
                    </div>
                  </li>
                  <li className="list-unstyled mt-2">
                    <div className="icon_arrow border-bottom">
                      <Link to="#" className="text-decoration-none">
                        <div className="d-flex ms-2 promenu">
                          <img src={user_4} alt="" />
                          <p className=" change-driver-list m-2 " > Diego Maradona</p>
                        </div>
                      </Link>
                      <div className="form-check p-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                        />
                      </div>
                    </div>
                  </li>
                </ul>

                <button
                  type="submit"
                  data-bs-dismiss="offcanvas"
                  className="btn m-3 mt-5 btn-change-driver"
                >
                  Save
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ChangeDriver;
