import React, { useState } from "react";
import { Link } from "react-router-dom";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import document_image from "../../assets/images/document_image.png";
import { Modal } from "react-bootstrap";

const GoodsDetails = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <main className="LoadDetails-main cx-active-sidebar" id="cxMain">
        <div
          className="tab-pane fade show active"
          id="pills-goodsdetails"
          role="tabpanel"
          aria-labelledby="pills-goodsdetails-tab"
        >
          {/* top  */}
          <div className="top-content common-body mt-4 d-flex justify-content-between align-items-center">
            <div className="driver-profile-details d-flex align-items-center">
              <div className="driver-name">
                <div className="title-driver">
                  <h4 className="name-driver">Leroy Sane</h4>
                </div>
                <div className="rating">
                  <img src={star_highlighted} alt="" />
                  <img src={star_highlighted} alt="" />
                  <img src={star_highlighted} alt="" />
                  <img src={star_highlighted} alt="" />
                  <img src={star_grey} alt="" />
                  <span>(320)</span>
                </div>
              </div>
            </div>
            <div className="view-detail-btn-load">
              <Link to="#" >
                View Details
              </Link>
            </div>
          </div>
          {/* From details section */}
          <section className="from-details">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">From</h5>
                  <hr />
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <label htmlFor="">Estimated Start Date</label>
                  <p className="data">22-Feb-2022</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Estimated Start Time</label>
                  <p className="data">10:30 AM</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Country</label>
                  <p className="data">India</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">City</label>
                  <p className="data">Mumbai</p>
                </div>
                <div className="col-12">
                  <label htmlFor="">Address</label>
                  <p className="data">
                    No.2/28H, Near Majid, Sainiketan Colony, Kalas Area
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* To details section */}
          <section className="to-details">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">To</h5>
                  <hr />
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Estimated Start Date</label>
                  <p className="data">22-Feb-2022</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Estimated Start Time</label>
                  <p className="data">10:30 AM</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Country</label>
                  <p className="data">India</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">City</label>
                  <p className="data">Mumbai</p>
                </div>
                <div className="col-12">
                  <label htmlFor="">Address</label>
                  <p className="data">
                    No.2/28H, Near Majid, Sainiketan Colony, Kalas Area
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* Goods details */}
          <section className="goods-details mb-4">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">Goods Details</h5>
                  <hr />
                </div>
                {/* left section */}
                <div className="col-lg-6">
                  <div className="image">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={Skyscrapers}
                            className="d-block "
                            alt={"..."}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={Skyscrapers}
                            className="d-block "
                            alt="..."
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={Skyscrapers}
                            className="d-block "
                            alt="..."
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* right section */}
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-4 mt-4">
                      <label htmlFor="">Reference Number</label>
                      <p className="data">ERWER4354</p>
                    </div>
                    <div className="col-lg-4 mt-4">
                      <label htmlFor="">Company Name</label>
                      <p className="data">Goods & Services</p>
                    </div>
                    <div className="col-lg-4 mt-4">
                      <label htmlFor="">Cargo Type</label>
                      <p className="data">Container with Type</p>
                    </div>
                    <div className="col-lg-4 mt-4">
                      <label htmlFor="">Quantity</label>
                      <p className="data">
                        5{" "}
                        <Link to="#" style={{ color: "#4FBFA4" }}>
                          View Reference No.
                        </Link>
                      </p>
                    </div>
                    <div className="col-lg-4 mt-4">
                      <label htmlFor="">Delivery Type</label>
                      <p className="data">import</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-content-box vehicle-requests-main-modal "
        >
          <Modal.Header closeButton></Modal.Header>
          <div>
            <p className="text-center popup-heading mt-4">
              Are you sure you want to send this offer to shipper?{" "}
            </p>
          </div>
          <div className="btn-popup-modal text-center">
            <button className="popup-reject-btn me-4 ">Reject </button>
            <button className="popup-approve-btn m-1 ">
              <Link
                to="/MatchMaking"
                className="text-decoration-none"
                style={{ color: "#4FBFA4" }}
              >
                Send
              </Link>
            </button>
          </div>
        </Modal>
      </main>
    </>
  );
};

export default GoodsDetails;
