import React, { useState, useContext, useEffect } from "react";
import right_arrow from "../../assets/images/ic_vdetails.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";

const AuctionDetails = () => {
  const auction = useLocation().state;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { customerData, mainClass } = useContext(AppContext);
  const [auctionDetails, setAuctionDetails] = useState({
    ...auction,
    best_bid: {},
    auction_details: {},
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getauctionDetails();
  }, []);
  const getauctionDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.AUCTION_DETAILS,
      JSON.stringify({
        ...customerData,
        load_id: auctionDetails.trip_id,
      })
    ).then((res) => {
      setLoading(false);
      if (res.json.result)
        setAuctionDetails({ ...auctionDetails, ...res.json });
    });
  };

  const acceptDirectOrder = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.ACCEPT_DIRECT_ORDER,
      JSON.stringify({ ...customerData, load_id: auctionDetails.trip_id })
    )
      .then((res) => {
        if (res.json.result) {
          swal(res.json.message);
          navigate("/OnlineVehicleOffers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const rejectDirectOrder = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.REJECT_DIRECT_ORDER,
      JSON.stringify({ ...customerData, load_id: auctionDetails.trip_id })
    )
      .then((res) => {
        if (res.json.result) {
          swal(res.json.message);
          navigate("/OnlineVehicleOffers");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main>
      <div className={`main  ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <>
            <div className="common-body  auction-detail-body">
              <div className="m-2">
                <h4 className="card-heading ">Auction Details</h4>

                <div className="user_block ">
                  <div
                    className=" justify-content-between align-items-start  "
                    id="short"
                  >
                    <div className="p-3  d-flex align-items-middle justify-content-between color-row-data  rsponsive-card-data">
                      <label className="">Auction Name </label>
                      <p className="">
                        {auctionDetails.auction_details.auction_name}{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    className=" justify-content-between align-items-start  "
                    id="days"
                  >
                    <div className="pt-2 pb-2 ps-3 pe-3  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                      <label className="">Auction Duration</label>
                      <p className="">
                        {auctionDetails.auction_details.duration}
                      </p>
                    </div>
                  </div>

                  <div
                    className=" justify-content-between align-items-start  "
                    id="short"
                  >
                    <div className="p-3 color-row-data d-flex align-items-middle justify-content-between  rsponsive-card-data">
                      <label className="">Auction Type</label>
                      <p className="">
                        {auctionDetails.auction_details.auction_type}
                      </p>
                    </div>
                  </div>

                  <div
                    className=" justify-content-between align-items-start  "
                    id="days"
                  >
                    <div className="pt-2 pb-2 ps-3 pe-3  d-flex align-items-middle justify-content-between  p-1 rsponsive-card-data">
                      <label className=""> Start Date</label>
                      <p className="">
                        {auctionDetails.auction_details.auction_start_date}
                      </p>
                    </div>
                  </div>
                  <div
                    className=" justify-content-between align-items-start "
                    id="short"
                  >
                    <div className="p-3 color-row-data d-flex align-items-middle justify-content-between  rsponsive-card-data">
                      <label className="">Expiry Date</label>
                      <p className="">
                        {auctionDetails.auction_details.auction_end_date}
                      </p>
                    </div>
                  </div>

                  <h4 className="card-heading mt-3 ">Best Offer</h4>

                  <div
                    className="p-3 color-row-data1 justify-content-between align-items-start mt-2 mb-4"
                    id="short"
                  >
                    <div
                      style={{ borderBottom: "1px solid #F5EDE7" }}
                      className="pb-2"
                    >
                      <div className="   d-flex align-items-middle justify-content-between  rsponsive-card-data">
                        <p className="">
                          {auctionDetails.best_bid.transporter_name}{" "}
                          {auctionDetails.best_bid.date}
                        </p>
                        <span className="amt-doller">
                          ${auctionDetails.best_bid.rate}
                        </span>
                      </div>
                    </div>
                    <div className="auction-accept-reject-btn mt-4">
                      <Link to="#" className="reject" onClick={handleShow}>
                        REJECT
                      </Link>
                      <Link to="#" className="accept" onClick={handleShow}>
                        ACCEPT
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className=" text-center w-100 btn-auction-details-bid "
                  style={{}}
                >
                  <Link
                    to={"/DetailsOfferGoods/" + auctionDetails.best_bid.load_id}
                    // onClick={() =>
                    //   navigate(

                    //   )
                    // }
                    className="d-flex 
                              justify-content-between
                              align-items-center
                              text-decoration-none"
                    id="save"
                  >
                    <span>View Goods Details</span>
                    <img src={right_arrow} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-content-box vehicle-requests-main-modal "
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="p-4">
          <div>
            <p className="text-center popup-heading mt-4">
              Are you sure you want to accept this offer?
            </p>
          </div>
          <div className="btn-popup-modal text-center">
            <button
              className="popup-reject-btn me-4 "
              onClick={() => {
                rejectDirectOrder();
              }}
            >
              Reject{" "}
            </button>
            <button className="popup-approve-btn m-1 ">
              <Link
                to="#"
                className="text-decoration-none "
                style={{ color: "#F08A29" }}
                onClick={() => {
                  acceptDirectOrder();
                }}
              >
                Accept
              </Link>
            </button>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export default AuctionDetails;
