import React,{useState, useContext} from "react";
import ic_mail from "../assets/images/ic_mail.svg";
import { AppContext } from "../context/user/AppContext";

const ContactUs = () => {
  const { mainClass } = useContext(AppContext);
  return (
    <>
   
    <main className={`main-body-contactUs  ${mainClass}`} id="cxMain" >
      <div className="contactUs" >
        <div className="contact-us ">
          {/* contact left section */}
          <section className="left-section-contact  w-100 p-2">
            <h3 className="left-heading">Abay Logistics</h3>
            <p className="left-paragraph">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
              voluptas pariatur ipsa error numquam commodi
            </p>
            <div className="email">
            <img src={ic_mail} alt="" /> <span className="email-contact"> abaylogistics@gmail.com</span>
            </div>
            
          </section>
          {/* contact right section */}
          <section className="right-section-contact p-2 w-100">
            <h3 className="get-in-touch">Get In Touch With Us</h3>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Subject</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="exampleFormControlTextarea1">Message</label>
              <textarea
                className="form-control text-area"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <div className="button mt-4">
                <a href="" className="continue-btn">Continue</a>
            </div>
          </section>
        </div>
      </div>
    </main>
  


    

    </>
  );
};

export default ContactUs;
